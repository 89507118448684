jQuery(function () {
    if (!$('.component-form').is(':visible')) {
        let urlHitQuery = false;
        $(document).ready(function () {


            var contextPathValue = $('#context-path-value').attr('data-context-path');
            var SearchModalUrl = window.location.origin + contextPathValue + "/api/Services/SearchModal/GetQuickLinks";

            // Function for adding the bottom border to the product name
            $('.productli').off('click').on('click', function () {
                $('.allSearchItems').removeClass('borderBottomOrange');
                $('.productli').addClass('borderBottomOrange');
                $('#tpwSearchResult').addClass('productCardView');
            });

            // Function for adding the bottom border to "All" and removing it from the product name
            $('.allSearchItems').off('click').on('click', function () {
                $('.productli').removeClass('borderBottomOrange');
                $('.allSearchItems').addClass('borderBottomOrange');
                $('#tpwSearchResult').removeClass('productCardView');
            });

            // Function to close the popup
            function closePopup() {
                $('#tpwSearchResult').hide();
                $('body').css('overflow', '');
                $('#overlay').fadeOut();
                $(".suggestionNoFoundResultPage").addClass('d-none');
                $("#popup").hide();
                $('body').removeClass('hide-scrollbar');
                $(".newAutocompleteList li").removeClass("borerBottomForSuggested");
                $(".cross-btn-onBar").hide().addClass("d-none");
                $(".header").show();
                $(".autocomplete-container").removeClass("marginTopLabel");
                crossButtonClickBar();
                if ($("#productSelectorSearchBtn").is(":visible")) {
                    $("#searchInputProd").val('').focus();;
                }
                $(".suggestionNoFound").addClass('d-none');
                $(".suggestionNoFoundResultPage").addClass('d-none');
                $(".links-container").removeClass('margin-top-for-quicklabel ');
            }


            $('#searchBar1, #searchIconForClick, #searchIconForClickTop, #searchButtonOnPopupOnMain, #productSelectorSearchBtn')
                .on('click', function (event) {
                    var clickedElement = $(this); // The element that was clicked
                    var clickedElementId = clickedElement.attr('id');

                    if (window.isSearchInProgress) {
                        return; // If search is already in progress, do nothing
                    }

                    // Set the flag to prevent multiple clicks
                    window.isSearchInProgress = true;

                    window.searchQuery = $('#focusFieldOfSearchHome').val();

                    // Call the function with the clicked element ID


                    // Call the function with the clicked element ID
                    fetchSearchData(clickedElementId);

                    // Reset the flag after a delay (assuming fetchSearchData is asynchronous)
                    setTimeout(function () {
                        window.isSearchInProgress = false;
                    }, 1000); // Adjust this delay according to how long fetchSearchData takes
                })

            $(".cross-btn-onBar").click(function () {
                crossButtonClickBar();
            });
            $('#productSelectorSearchBtn')
                .off('click')
                .on('click', function () {
                    window.searchQuery = $('#searchInputProd').val();
                    var clickedElementIds = "productSelectorSearchBtn";
                    if (!(window.searchQuery == "")) {
                        fetchSearchData(clickedElementIds);
                    }
                });


            let isProcessing = false;
            $('#searchInputProd').keypress(function (event) {
                if (event.which == 13 && !isProcessing) {
                    event.preventDefault(); // Prevent default form submission behavior
                    isProcessing = true;
                    window.searchQuery = $('#searchInputProd').val();
                    var clickedElementIds = "productSelectorSearchBtn";
                    if (window.searchQuery.trim() !== "") {
                        fetchSearchData(clickedElementIds).finally(() => {
                            isProcessing = false; // Reset flag after fetch completes
                        });
                    } else {
                        isProcessing = false; // Reset flag if no search query
                    }
                }
            });
            let searchPerformedFlag = false;

            function fetchSearchData(clickedElementId) {
                $.ajax({
                    url: SearchModalUrl,
                    type: 'POST',
                    contentType: 'text/html; charset=utf-8', // Set the correct content-type header for the request
                    dataType: 'html',
                    success: function (data) {
                        if (clickedElementId === "searchButtonOnPopupOnMain" || clickedElementId === "productSelectorSearchBtn" || urlHitQuery == true) {
                            performSearch();
                            searchPerformedFlag = true;
                        }
                        $('.main-search-header-data').html(data);

                        // $(".header").hide();
                        //$('#focusFieldOfSearch').focus();

                        if (!searchPerformedFlag) {
                            $('body').addClass('hide-scrollbar');
                            $(".suggestionNoFound").addClass('d-none');
                            $("#popup").show();
                            $('#overlay').fadeIn();
                            $('body').css('overflow', 'hidden');
                            $('#focusFieldOfSearch').focus();
                        }
                        $("#popupCloseButton").click(async function () {
                            await new Promise(resolve => setTimeout(resolve, 100));
                            var url = window.location.href;
                            var baseUrl = url.split('?')[0];
                            window.history.replaceState(null, null, baseUrl);
                            closePopup();
                        });
                        $(".cross-btn-onBar").click(function () {
                            // $('.search-container-popup').removeClass("margin-seventin");
                            $(".my-search-box").val('').focus();
                            $(".label-quiclLinks").show();
                            $(".links-container").show();
                            $(".cross-btn-onBar").hide().addClass("d-none");
                        });
                        $(".my-search-box").on('input', function () {
                            if ($(this).val().length > 0) {
                                $(".cross-btn-onBar").show();
                            } else {
                                $(".cross-btn-onBar").hide();
                            }
                        });

                        // Set tabindex for interactive elements
                        $("#popup .popup-content button, #popup .popup-content input, #popup .popup-content a").attr("tabindex", "0");
                        $(".links-container span").attr("tabindex", "0");


                        // Handle keyboard interactions for buttons and links
                        $("#popup").on("keydown", "button, a", function (event) {
                            if (event.key === "Enter" || event.key === " ") {
                                $(this).click();
                            }
                        });
                        //applyGridViewWidth();
                    },
                    error: function (xhr, status, error) {
                        console.error("Error fetching quick links:", error);
                    },
                    complete: function () {
                        // Clean up
                        clickedElementId = null;
                        searchPerformedFlag = false;
                        urlHitQuery = false;
                    }

                });
            }

            function crossButtonClickBar() {

                $(".label-quiclLinks").removeClass("custom-padding-quickLinks");
                $('#tpwSearchResult').hide();
                if ($("#popup").is(":visible")) {
                    $("#focusFieldOfSearch").val('').focus();
                } else {
                    $(".my-search-box").val('').focus();
                }

                $(".cross-btn-onBar").addClass("d-none");
                $(".autocomplete-container").removeClass("marginTopLabel");
                $(".autocomplete-label").hide();
                $("#newAutocompleteList").hide();
                $(".newAutocompleteList").hide();
                $(".suggestionNoFound").addClass('d-none');
                $(".suggestionNoFoundResultPage").addClass('d-none');
                $(".links-container").removeClass('margin-top-for-quicklabel ');
                $(".discover-section").show();
                $(".new-search").addClass("footer-for-searchBar");
            }

            let autocompleteInput = $(".my-search-box");
            const autocompleteList = $("#newAutocompleteList");

            var URL = window.location.origin + contextPathValue + "/api/Services/Search/GetSuggestions";

            autocompleteInput.off("keyup").on("keyup", function (event) {

                $(".label-quiclLinks").removeClass("custom-padding-quickLinks");
                $(".cross-btn-onBar").removeClass("d-none");
                var searchTermN = $(this).val().trim();

                if (searchTermN.length > 0 && URL != "") {
                    $.ajax({
                        type: "POST",
                        url: URL,
                        dataType: "json",
                        data: {
                            text: searchTermN
                        },
                        success: function (jsonData) {
                            var myData = JSON.parse(jsonData);
                            const regex = new RegExp(`(${searchTermN})`, 'gi');
                            const autocompleteItems = myData.map(function (item) {
                                const highlightedItem = item.replace(regex, '<strong>$1</strong>');
                                return `<li tabindex="0">${highlightedItem}</li>`;
                            }).join("");
                            if (Array.isArray(myData) && myData.length > 0) {
                                $(".autocomplete-container").addClass("marginTopLabel");
                            } else {
                                hideSuggestedData();
                                return;
                            }
                            $(".new-search").removeClass("footer-for-searchBar");
                            if ($("#popup").is(":visible")) {
                                $(".label-quiclLinks").hide();
                                $(".links-container").hide();
                            }
                            $(".discover-section").hide();
                            $("#tpwSearchResult").hide();
                            if (($(".autocomplete-label").is(":visible")) || ($(".newAutocompleteList").is(":visible"))) {
                                $(".newAutocompleteList").hide();
                                $(".autocomplete-label").hide();
                            }
                            $(".suggestionNoFound").addClass('d-none');
                            $(".suggestionNoFoundResultPage").addClass('d-none');
                            $(".label-quiclLinks").removeClass('paddingTopForQlabel');
                            $(".links-container").removeClass('margin-top-for-quicklabel ');
                            $(".autocomplete-label").removeClass('d-none');
                            $("#newAutocompleteList").html(autocompleteItems);
                            $(".newAutocompleteList").html(autocompleteItems);
                            $("#newAutocompleteList").show();
                            $(".newAutocompleteList").show();
                            $(".autocomplete-label").show()

                            if ($("#popup").is(":visible")) {
                                $(".newAutocompleteList li").addClass("borerBottomForSuggested");
                            }
                        },
                        error: function (xhr, status, error) {
                            console.error("Error:", error);
                        },
                        complete: function () {
                            searchTermN = null;
                            myData = null;
                            autocompleteItems = null;
                        }
                    });
                } else {
                    hideSuggestedData();
                }

            });

            function hideSuggestedData() {

                if (!$("#popup").is(":visible")) {
                    window.valueOfHomeSearchBar = $('#focusFieldOfSearchHome').val(); // Assign value from focusFieldOfSearchHome if it's 
                    console.log('Captured value:', window.valueOfHomeSearchBar);

                } else if ($("#popup").is(":visible")) {
                    window.valueOfHomeSearchBar = $('#focusFieldOfSearch').val(); // Assign value from focusFieldOfSearch if focusFieldOfSearchHome is not visible
                    console.log('Captured value:', window.valueOfHomeSearchBar);
                }
                $(".autocomplete-container").removeClass("marginTopLabel");
                $(".autocomplete-label").hide();
                $("#newAutocompleteList").hide();
                $(".newAutocompleteList").hide();

                if ($("#popup").is(":visible")) {
                    $('#tpwSearchResult').hide();
                    if (window.valueOfHomeSearchBar === "") {
                        $(".suggestionNoFoundResultPage").addClass('d-none');
                        $(".label-quiclLinks").removeClass('paddingTopForQlabel');
                        $(".links-container").removeClass('margin-top-for-quicklabel ');
                    } else {
                        //$('#SearchedWord').text(' " ' + window.valueOfHomeSearchBar + ' " ');
                        $('.suggestionNoFoundResultPage').find('#SearchedWord').text('"' + window.valueOfHomeSearchBar + '"');
                        $(".suggestionNoFoundResultPage").removeClass('d-none');
                        $(".links-container").addClass('margin-top-for-quicklabel ');

                        $(".label-quiclLinks").addClass('paddingTopForQlabel');

                    }
                    $(".cross-btn-onBar").removeClass("d-none");
                    $(".label-quiclLinks").show();
                    $(".links-container").show();
                }

                if (!$("#popup").is(":visible")) {
                    $(".discover-section").show();
                    if (window.valueOfHomeSearchBar === "") {
                        $(".suggestionNoFound").addClass('d-none');
                        $(".discover-section").removeClass('margin-topDesk32Imob24');
                        $(".cross-btn-onBar").addClass("d-none");
                    } else {
                        // Set the value into the span with id noSuggestion
                        $(".cross-btn-onBar").removeClass("d-none");
                        //$('#SearchedWord').text(' " ' + window.valueOfHomeSearchBar + ' " ');
                        $('.suggestionNoFound').find('#SearchedWord').text('"' + window.valueOfHomeSearchBar + '"');
                        $(".suggestionNoFound").removeClass('d-none');
                        $(".discover-section").addClass('margin-topDesk32Imob24');

                    }
                }
                $('#noSuggestion').text(window.valueOfHomeSearchBar);
                // $(".cross-btn-onBar").addClass("d-none");
                $(".new-search").addClass("footer-for-searchBar");

            }
            autocompleteList.on("click keydown", "li", function (event) {
                $('#tpwSearchResult').removeClass('productCardView');
                if (event.type === "click" || event.key === "Enter") {
                    if (!$("#popup").is(":visible")) {
                        fetchSearchData();
                        $(".discover-section").hide();
                    }
                    autocompleteInput.val($(this).text());
                    var selectedText = $(this).text();
                    var url = window.location.origin + contextPathValue + "/api/Services/Search/TpwSearch";
                    $.ajax({
                        url: url,
                        type: 'POST',
                        data: {
                            query: $(this).text()
                        },
                        success: function (data) {
                            $(".label-quiclLinks").hide();
                            $(".links-container").hide();
                            $('#focusFieldOfSearch').val(selectedText);
                            $(".cross-btn-onBar").removeClass("d-none");
                            $(".autocomplete-label").hide();
                            $("#newAutocompleteList").hide();
                            $('#tpwSearchResult').html(data.replace('>«<', '><').replace('>»<', '><'));
                            $("#tpwSearchResult").show();
                            if ($(".col-12.gridViewActive").is(":visible")) {
                                applyGridViewWidth();
                            }

                            if (typeof window.HMSPowerReviews === 'function') {
                                new window.HMSPowerReviews();
                            } else {
                                var scriptPath = window.location.origin + window.location.pathname.split('/')[1] + '/assets/scripts/new-tpw-jquery/PowerReviews.js';
                                var script = document.createElement('script');
                                script.src = scriptPath;

                                script.onload = function () {
                                    if (typeof window.HMSPowerReviews === 'function') {
                                        new window.HMSPowerReviews();
                                    } else {
                                        console.log("Power Reviews initialization function not found even after script load.");
                                    }
                                };
                            }
                        },
                        error: function (xhr, status, error) {
                            console.error("Error:", error);
                        },
                        complete: function () {
                            selectedText = null;
                            url = null;
                        }
                    });
                }
            });

            var resultURl = window.location.origin + contextPathValue + "/api/Services/Search/TpwSearch";

            $(document).ready(function () {
                $(document).on("click", "#searchButtonOnPopupOnMain", function () {
                    if (typeof window.HMSPowerReviews === 'function') {
                        new window.HMSPowerReviews();
                    } else {
                        console.log("Power Reviews initialization function not found even after script load.");
                    }
                });
            });

            $(document).ready(function () {
                $(document).on("click", ".searchButtonOnPopup", function () {
                    if (typeof window.HMSPowerReviews === 'function') {
                        new window.HMSPowerReviews();
                    } else {
                        console.log("Power Reviews initialization function not found even after script load.");
                    }
                });
            });

            function performSearch() {
                $('#tpwSearchResult').removeClass('productCardView');
                var searchQuery = window.searchQuery || $('.my-search-box').val();
                updateQueryStringParam('q', searchQuery);
                $.ajax({
                    url: resultURl,
                    type: 'POST',
                    data: {
                        query: searchQuery.split(/[&%]/)[0]
                    },
                    success: function (data) {
                        $(".label-quiclLinks").hide();
                        $(".links-container").hide();
                        $(".autocomplete-label").hide();
                        $("#newAutocompleteList").hide();
                        $('#divForLableForSearch').hide();
                        $('#tpwSearchResult').html(data.replace('>«<', '><').replace('>»<', '><'));

                        if (data.includes('<div class="section-description none-results-search-page">')) {

                            $('#tpwSearchResult').find('#SearchedWord').html('"' + searchQuery + '"');


                            $(".label-quiclLinks").show();
                            $(".links-container").show();
                            $(".label-quiclLinks").addClass("custom-padding-quickLinks");

                        } else {
                            $(".label-quiclLinks").removeClass("custom-padding-quickLinks");
                        }

                        if (searchQuery) {
                            // Split the search query at the first occurrence of either '&' or '%'
                            let partBeforeSpecialChars = searchQuery.split(/[&%]/)[0]; // Get the part before '&' or '%'

                            // Now decode the URL-encoded string and replace '+' with spaces
                            let cleanedSearchQuery = decodeURIComponent(partBeforeSpecialChars).replace(/\+/g, ' ');

                            // Set the cleaned value to the input field
                            $('#focusFieldOfSearch').val(cleanedSearchQuery.trim());

                            // Show the cross button
                            $(".cross-btn-onBar").removeClass("d-none");
                        }

                        $(document).ready(function () {
                            if (typeof window.HMSPowerReviews === 'function') {
                                new window.HMSPowerReviews();
                            } else {
                                var scriptPath = window.location.origin + window.location.pathname.split('/')[1] + '/assets/scripts/new-tpw-jquery/PowerReviews.js';
                                var script = document.createElement('script');
                                script.src = scriptPath;

                                script.onload = function () {
                                    if (typeof window.HMSPowerReviews === 'function') {
                                        new window.HMSPowerReviews();
                                    } else {
                                        console.log("Power Reviews initialization function not found even after script load.");
                                    }
                                };
                            }
                        });
                        if ($(".suggestionNoFoundResultPage").is(":visible")) {
                            $(".suggestionNoFoundResultPage").addClass('d-none');
                            $(".links-container").removeClass('margin-top-for-quicklabel ');
                            $(".links-containe").removeClass('d-none');
                        }
                        $("#tpwSearchResult").show();
                        if ($(".col-12.gridViewActive").is(":visible")) {
                            applyGridViewWidth();
                        }
                        return;
                    },

                    error: function (xhr, status, error) {
                        console.error("Error:", error);
                    },
                    complete: function () {
                        searchQuery = null;

                        $('body').addClass('hide-scrollbar');
                        $("#popup").show();
                        $('#overlay').fadeIn();
                        $('body').css('overflow', 'hidden');
                        if ($(".col-12.gridViewActive").is(":visible")) {
                            applyGridViewWidth();
                        }

                        // $('#focusFieldOfSearch').focus();
                        var queryString = decodeURIComponent(window.location.search);
                        var urlParams = new URLSearchParams(queryString);
                        if (urlParams.has('facet_documentType') && urlParams.get('facet_documentType') === 'product') {
                            // Use a small timeout to ensure the popup is visible and fully rendered
                            setTimeout(function () {
                                if ($('.productli a').length) {
                                    $('.productli a')[0].click(); // Trigger click on the first anchor tag in .productli
                                } else {
                                    console.error("Anchor tag not found.");
                                }
                            }, 500); // Adjust the delay if necessary
                        }

                    }
                });

                window.searchQuery = null;
            }

            function updateQueryStringParam(param, value) {
                var queryString = window.location.search;
                var urlParams = new URLSearchParams(queryString);

                // Update or set the 'q' parameter
                urlParams.set(param, value);

                // Update the URL without reloading the page
                var newUrl = window.location.pathname + '?' + urlParams.toString();

                // Update the browser's history state to reflect the new URL
                window.history.replaceState(null, null, newUrl);
            }


            let enterTimeout = null; // Timer variable to track the 100ms delay


            $('.my-search-box').on('keyup', function (event) {
                enterClicked(event);
            });

            function enterClicked(event) {
                // If there is an ongoing enter event within 100ms, ignore the new one
                if (enterTimeout) {
                    return; // Ignore event if within 100ms of previous event
                }
                // Set the timeout for 100ms after the first enter event
                enterTimeout = setTimeout(function () {
                    enterTimeout = null; // Reset timeout after 100ms
                }, 200);
                // Proceed with normal processing if Enter key is pressed
                if (event.which === 13) {
                    if ($("#popup").is(":visible")) {
                        window.searchQueryOnpopup = $('#focusFieldOfSearch').val();
                        if (window.searchQueryOnpopup != "") {
                            performSearch();
                        }
                    } else {
                        event.preventDefault(); // Prevent default form submission behavior

                        window.searchQuery = $('#focusFieldOfSearchHome').val();
                        //window.searchQuery = $('.my-search-box').val();
                        var clickedElementIds = "productSelectorSearchBtn";
                        if (window.searchQuery != "") {
                            fetchSearchData(clickedElementIds);
                        }
                    }
                }
            }
            $(document).ready(function () {
                $('.searchButtonOnPopup').off('click').on('click', function () {
                    window.searchQueryOnpopup = $('#focusFieldOfSearch').val();
                    if (window.searchQueryOnpopup != "") {
                        performSearch();
                    }
                    //performSearch();
                });
            });

            $('#tpwSearchResult').off('click', 'ul.pagination li a').on('click', 'ul.pagination li a', function (event) {
                event.preventDefault();
                var url = $(this).attr('href');
                $.ajax({
                    url: url,
                    type: 'GET',
                    success: function (data) {
                        $('#divForLableForSearch').hide();
                        $('#tpwSearchData').html(data.replace('>«<', '><').replace('>»<', '><'));
                        if (typeof window.HMSPowerReviews === 'function') {
                            new window.HMSPowerReviews();
                        } else {
                            console.error("Power Reviews initialization function not found.");
                        }
                        heightCalculateForHeading();
                        heightCalculateForParadiv();
                        if ($(".col-12.gridViewActive").is(":visible")) {
                            applyGridViewWidth();
                        }

                    },
                    error: function (xhr, status, error) {
                        console.error("Error:", error);
                    },
                    complete: function () {
                        document.querySelector('.popup').scrollTo(0, 0);
                        url = null;
                    }
                });
            });


            function heightCalculateForHeading() {
                var maxHeight = 0;

                // Loop through each h3 element inside .tpwProductBoxText within the .cardwrapper.productList.equal-card and find the max height
                $('.cardwrapper.productList.equal-card .tpwProductBoxText h3').each(function () {
                    var currentHeight = $(this).outerHeight();
                    if (currentHeight > maxHeight) {
                        maxHeight = currentHeight;
                    }
                });


                // Apply the max height to all h3 elements within .tpwProductBoxText inside .cardwrapper.productList.equal-card
                $('.cardwrapper.productList.equal-card .tpwProductBoxText h3').css('height', maxHeight + 'px');

            }

            function applyGridViewWidth() {
                // Get the width of the parent element
                let upcomingElement = document.querySelector('.col-12.gridViewActive');

                if (upcomingElement) {
                    let upcomingWidth = upcomingElement.offsetWidth;

                    // Only update if the new width is greater than 0
                    if (upcomingWidth > 0) {
                        window.gridViewActiveWidth = upcomingWidth;
                    }

                    // Apply the width to all search-result-content elements
                    let searchResultContents = document.querySelectorAll('.col-8.col-sm-12.search-result-content');
                    searchResultContents.forEach((element) => {
                        element.style.width = `${window.gridViewActiveWidth}px`;
                    });

                    let productBoxes = document.querySelectorAll('.tpwProductBox.tpwProductCompact');
                    productBoxes.forEach((box) => {
                        box.style.width = `${window.gridViewActiveWidth}px`;
                    });
                } else {
                    console.error('Element with class .col-12.gridViewActive not found');
                }
            }

            // Call the function

            function heightCalculateForParadiv() {
                var maxHeight = 0;

                // Loop through each h3 element inside .tpwProductBoxText within the .cardwrapper.productList.equal-card and find the max height
                $('.cardwrapper.productList.equal-card .tpwDetail .paraDiv').each(function () {
                    var currentHeight = $(this).outerHeight();
                    if (currentHeight > maxHeight) {
                        maxHeight = currentHeight;
                    }
                });

                // Apply the max height to all h3 elements within .tpwProductBoxText inside .cardwrapper.productList.equal-card
                $('.cardwrapper.productList.equal-card .tpwDetail .paraDiv').css('height', maxHeight + 'px');

            }

            let isLoading = false; // Flag to check if an AJAX call is in progress

            $('.productli a').on('click', function (event) {

                event.preventDefault();
                // Check if the clicked element is '.productli' or 'a' inside '.productli'
                if ($(event.target).is('.productli')) {

                    event.preventDefault(); // Stop default behavior
                    event.stopPropagation(); // Stop event from bubbling up
                } else if ($(event.target).is('.productli a') && (flagForProductItemsClicked)) {
                    $('.component-pagination').hide();
                    $('#divForLableForSearch').hide();


                    const accordionTitle = document.querySelector('.accordion-title.js-accordion-title');

                    // Check if the element is not present or not visible
                    if (!accordionTitle || accordionTitle.offsetParent === null) {
                        $('.ProductDetailCard').html("");
                        console.log("niitn");
                    }


                }
                flagForAllItemsClicked = true;

                if (flagForProductItemsClicked) {
                    const accordionTitle = document.querySelector('.accordion-title.js-accordion-title');
                    $('.component-pagination').hide();
                    $('#divForLableForSearch').hide();
                    if (!accordionTitle || accordionTitle.offsetParent === null) {
                        $('.ProductDetailCard').html("");
                        console.log("niitn");
                    }


                    // Check if an AJAX call is already in progress
                    if (isLoading) return;
                    isLoading = true; // Set the flag to true when the AJAX call starts
                    var url = $(this).attr('href');

                    if (typeof url !== 'undefined') {
                        window.currentURLProduct = url;
                    }
                    url = window.currentURLProduct;
                    var queryString = getQueryString(url);
                    updateQueryStringParam('q', queryString);
                    $.ajax({
                        url: url,
                        type: 'GET',
                        success: async function (data) {
                            // Insert the new data
                            $('#tpwSearchData').html(data.replace('>«<', '><').replace('>»<', '><'));


                            heightCalculateForHeading();
                            heightCalculateForParadiv();

                            //$('#tpwSearchData').hide();

                            // Initialize Power Reviews if available
                            if (typeof window.HMSPowerReviews === 'function') {
                                new window.HMSPowerReviews();
                            } else {
                                console.error("Power Reviews initialization function not found.");
                            }
                            if (!$(".pagination-container").is(':visible')) {
                                $('.cardwrapper.productList.equal-card').css('padding-bottom', '64px');
                            } else {
                                $('.cardwrapper.productList.equal-card').css('padding-bottom', '0');
                            }
                            flagForProductItemsClicked = false;

                            // setTimeout(() => {
                            //     debugger
                            //     $('#tpwSearchData').show(); // Show the content after 200ms
                            // }, 200);
                            $('#tpwSearchData').show();

                        },
                        error: function (xhr, status, error) {
                            console.error("Error:", error);
                        },
                        complete: function () {
                            isLoading = false; // Reset the flag when the AJAX call completes
                            //url = null; // Clear the URL variable after completion
                        }
                    });
                } else {
                    event.preventDefault();
                    return;
                }
            });

            $(document).ready(function () {
                $(document).on('keydown', function (event) {
                    if (event.key === 'Enter' || event.keyCode === 13) {
                        // Get the currently focused element
                        var focusedElement = $(document.activeElement);

                        // Check if the focused element is inside a .component-btn div
                        focusedElement.click();
                    }
                });
            });

            function getQueryString(url) {
                var queryString = url.split('?')[1]; // Get everything after the '?' in the URL
                if (!queryString) {
                    return '';
                }
                queryString = queryString.replace(/(^|&)q=([^&]*)(&|$)/, '$1$2$3').replace(/&&/g, '&').replace(/&$/, '');

                return queryString;
            }
            var flagForAllItemsClicked = false;
            var flagForProductItemsClicked = true;
            $('.allSearchItems a').on('click', function (event) {
                if ($(".ProductDetailCard").is(':visible')) {
                    // Check if the clicked element is '.allSearchItems' or 'a' inside '.allSearchItems'
                    if ($(event.target).is('.allSearchItems')) {

                        event.preventDefault(); // Stop default behavior
                        event.stopPropagation(); // Stop event from bubbling up
                    } else if ($(event.target).is('.allSearchItems a')) {
                        console.log('Clicked on .allSearchItems a');
                    }
                    flagForProductItemsClicked = true;
                    if (flagForAllItemsClicked) {

                        $('.component-pagination').hide();
                        $('#divForLableForSearch').hide();
                        $('.ProductDetailCard').html("");

                        event.preventDefault();
                        var url = $(this).attr('href');
                        if (typeof url !== 'undefined') {
                            window.currentURL = url;
                        }
                        url = window.currentURL;
                        var queryString = getQueryString(url);
                        updateQueryStringParam('q', queryString);
                        $.ajax({
                            url: url,
                            type: 'GET',
                            success: function (data) {
                                $('#divForLableForSearch').hide();
                                $('#tpwSearchData').html(data.replace('>«<', '><').replace('>»<', '><'));
                                $('#tpwSearchData').hide();
                                if (typeof window.HMSPowerReviews === 'function') {
                                    new window.HMSPowerReviews();
                                } else {
                                    console.error("Power Reviews initialization function not found.");
                                }

                                let searchResultContents = document.querySelectorAll('.col-8.col-sm-12.search-result-content');
                                searchResultContents.forEach((element) => {
                                    element.style.width = `${window.gridViewActiveWidth}px`;
                                });
                                let productBoxes = document.querySelectorAll('.tpwProductBox.tpwProductCompact');
                                productBoxes.forEach((box) => {
                                    box.style.width = `${window.gridViewActiveWidth}px`;
                                });
                                flagForAllItemsClicked = false;
                                // setTimeout(() => {
                                $('#tpwSearchData').show(); // Show the content after 200ms
                                applyGridViewWidth();

                                //}, 200);
                            },
                            error: function (xhr, status, error) {
                                console.error("Error:", error);
                            },
                            complete: function () { }
                        });
                    } else {
                        event.preventDefault();
                        return;
                    }
                }
            });
            var queryString = window.location.search;
            var urlParams = new URLSearchParams(queryString);
            window.onload = function () {
                if (urlParams.has('q')) {
                    if (!$("#popup").is(':visible')) {
                        // Retrieve and store the search query
                        window.searchQuery = urlParams.get('q');

                        urlHitQuery = true;

                        // Perform the search and open the popup
                        fetchSearchData();
                    }
                }
            };
        });
    }
});

